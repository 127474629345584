<template>
  <div class="table_wrapper">
    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="item in items" :disabled="!item.active" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card max-width="1000" class="mx-auto my-5">
            <v-expand-transition>
              <v-alert dense text type="success" v-if="statu_success">
                Data has been successfully updated.
              </v-alert>
            </v-expand-transition>
            <v-layout wrap>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.title"
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.description"
                  label="Description"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.store_description_limit"
                  label="Store description limit (characters)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.product_image_limit"
                  label="Images per product limit"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.product_video_limit"
                  label="Videos per product limit"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.product_limit"
                  label="Products limit"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.rfq_limit"
                  label="RFQ request limit"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.rfq_offer_limit"
                  label="RFQ offers limit"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-switch
                  inset
                  v-model="formData.rfq_info"
                  label="RFQ info"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  inset
                  v-model="formData.priority_on_search_pages"
                  label="Priority on search pages"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  inset
                  v-model="formData.postour_social_media"
                  label="Post it on our social media accounts"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  inset
                  v-model="formData.sub_domain"
                  label="Sub domain"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  inset
                  v-model="formData.verified"
                  label="Verification"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  inset
                  v-model="formData.trust_seal"
                  label="Trusted Seal"
                ></v-switch>
              </v-col>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                color="success"
                :loading="disabled"
                v-on:click="updateData"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "SubscriptionsEdit",
  data() {
    return {
      data: [],
      tab: null,
      disabled: false,
      statu_success: false,
      items: [
        { tab: "Package Details", active: true },
        { tab: "Translation", active: false },
        { tab: "Additional Package Details", active: false },
      ],
      token: localStorage.getItem("authToken"),
      page: 1,
      per_page: 10,
      formData: {
        description: "",
        details: [],
        is_enabled: null,
        postour_social_media: null,
        price: null,
        priority_on_search_pages: null,
        product_image_limit: null,
        product_limit: null,
        product_video_limit: null,
        rfq_info: null,
        rfq_limit: null,
        rfq_offer_limit: null,
        store_description_limit: null,
        sub_domain: null,
        title: "",
        translations: {},
        trust_seal: null,
        try_price: "",
        valid_days: null,
        verified: null,
      },
    };
  },
  methods: {
    updateData: function() {
      this.disabled = true;
      const url =
        process.env.VUE_APP_API_URL +
        "packages/" +
        this.$route.params.id +
        "?&api_token=" +
        this.token;
      axios
        .put(url, this.formData)
        .then((res) => {
          this.formData = res.data;
          this.statu_success = true;
          this.disabled = false;
          let th = this;
          setTimeout(() => {
            th.statu_success = false;
          }, 4000);
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    const url =
      process.env.VUE_APP_API_URL +
      "packages/" +
      this.$route.params.id +
      "?&api_token=" +
      this.token;
    axios
      .get(url)
      .then((res) => {
        this.formData = res.data;
      })
      .catch((err) => console.log(err));
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
